<!-- 同比分析 -->
<template>
  <div id="year-analysis">
    <div class="main">
      <div class="query-form">
        <div class="title">
          <img src="../../assets/images/LTicon.png" alt="" />
          <span>同比分析</span>
        </div>

      </div>
      <div class="" style="margin-top: 15px;">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item class="query-date">
            <el-date-picker :append-to-body="false" placeholder="请选择日期" type="year" value-format="yyyy-MM-dd" v-model="formData.time"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="previousYear(0)" icon="el-icon-arrow-left">上一年</el-button>
            <el-button type="primary" @click="previousYear(1)">下一年<i
                class="el-icon-arrow-right el-icon--right"></i></el-button>
            <el-button @click="drawChart" type="primary" icon="el-icon-search">搜索</el-button>
            <!-- <el-button type="primary" icon="el-icon-download" @click="exportDialog=true">导出</el-button> -->
          </el-form-item>
        </el-form>
      </div>

      <div class="content">
        <div id="chart"></div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="moth" label="月份">
          </el-table-column>
          <el-table-column align="center" prop="nowDianNeng" label="本期/KWh">
          </el-table-column>
          <el-table-column align="center" prop="lastDianNeng" label="同期/KWh">
          </el-table-column>
          <el-table-column align="center" prop="compareYear" label="同比/%">
          </el-table-column>
          <!-- <el-table-column align="center" prop="three" label="累计同比">
          </el-table-column> -->
        </el-table>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog" width="20%">
      <span class="dialog-content">确定要导出数据吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import { eventApi } from "@/api/event.js";
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
      },
      exportDialog: false,
      tableData: [
        {
          name: "一月",
          zero: "95288",
          one: "126622",
          two: "-27.1",
          three: "-24.75",
        },
        {
          name: "二月",
          zero: "95288",
          one: "126622",
          two: "-27.1",
          three: "-24.75",
        },
        {
          name: "三月",
          zero: "95288",
          one: "126622",
          two: "-27.1",
          three: "-24.75",
        },
      ],
      select: 0,
      stationNumber: '',


    };
  },
  created(){
  this.$store.commit('increment', '用电分析')
  this.$store.commit('selectChild', '同比分析')
  this.$store.commit("selectChildren", "");
},

  mounted() {
    this.$store.commit('increment', '用电分析')
    this.$store.commit("selectChildren", "");
    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.drawChart();
  },

  methods: {
    //上一年or下一年
    previousYear(type) {
      if (type == 0) {
        console.log("1111111111111111");
        if (this.formData.time) {
          let prevYear = new Date(this.formData.time)
          prevYear.setFullYear(prevYear.getFullYear() - 1)
          // this.formData.time = prevYear
          let date = new Date(prevYear);
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); 
        let day = ('0' + date.getDate()).slice(-2); 
        this.formData.time = `${year}-${month}-${day}`
          // console.log(this.formData.time);
        }
      } else {
        if (this.formData.time) {
          let nextYear = new Date(this.formData.time)
          nextYear.setFullYear(nextYear.getFullYear() + 1)
          let date = new Date(nextYear);
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); 
        let day = ('0' + date.getDate()).slice(-2); 
        this.formData.time = `${year}-${month}-${day}`
        }
      }
    },
    switchTabs(index) {
      this.select = index;
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
    },
    //图表渲染
    drawChart() {
      let obj = {
        stationNumber: this.stationNumber,
        time: this.formData.time
      }
      if (!this.formData.time) {
        var today = new Date();
        obj.time = today.getFullYear();
      }else{
        console.log(this.formData.time);
        obj.time = this.formData.time.substring(0,4)
      }
      emissionsCarbonApi.analysisOneApi(obj).then(res => {
        this.tableData=[]
        
        let now = []
        let last = []
        if (res.data) {
          res.data.forEach(item => {
            now.push(item.nowDianNeng)
            last.push(item.lastDianNeng)
              console.log(item);
              item.moth=item.nowTs.substring(5,7) + '月'
            this.tableData.push(item)
          })
          var chart = echarts.init(document.getElementById("chart"));
          // 指定图表的配置项和数据
          var option = {
            tooltip: {},
            legend: {
              data: ['本期', '同期',],
              right: '100px',
              top: '16px',
              textStyle: {
                color: "#AED6FF"
              }
            },
            color: ['#2a65b4', ' #2fc9cb'],
            xAxis: {
              data: [
                "1月",
                "2月",
                "3月",
                "4月",
                "5月",
                "6月",
                "7月",
                "8月",
                "9月",
                "10月",
                "11月",
                "12月",
              ],
              axisLabel: {
                textStyle: {
                  color: "#aed6ff", // 设置字体颜色
                  fontSize: 12,
                },
              },
            },
            yAxis: {
              name: "单位:KWh",
              nameTextStyle: {
                color: "#aed6ff",
              },
              // data: [0,1000,2000,3000,4000,5000,6000],
              axisLabel: {
                textStyle: {
                  color: "#aed6ff", // 设置字体颜色
                },
              },
              splitLine: {
                show: false,
              },
            },
            series: [
              {
                name: "本期",
                type: "bar",
                barWidth: 20,
                data: now,
              },
              {
                name: "同期",
                type: "bar",
                barWidth: 20,
                data: last,
              },
            ],
          };

          // 使用刚指定的配置项和数据显示图表。
          chart.setOption(option);
        }
      })
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#year-analysis {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 100%;
  min-width: 1280px;
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

#chart {
  height: 316px;
  width: 100%;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}

.dialog-content {
  font-size: 16px;
  color: #ebedf1;
}
::v-deep .el-table__body-wrapper {
  height: 50vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
